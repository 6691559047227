import React from 'react';
import { motion } from 'framer-motion';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

// Import images
import vipAirport from '../assets/images/vip-airport.png';
import corporateTransfer from '../assets/images/corporate-transfer.png';
import vipEvents from '../assets/images/vip-events.png';
import luxuryTransport from '../assets/images/luxury-transport.png';
import servicesHero from '../assets/images/services.webp';

const Services = () => {
  const navigate = useNavigate();

  const handleContactNavigation = () => {
    navigate('/contact');
  };

  const services = [
    {
      title: 'Airport Transfer',
      description: 'Premium airport transfer service with meet & greet, flight tracking, and luggage assistance.',
      features: [
        'Flight tracking & monitoring',
        'Meet & greet service',
        'Luggage assistance',
        'No hidden charges',
        'Free waiting time',
      ],
      image: vipAirport
    },
    {
      title: 'Corporate Transportation',
      description: 'Professional transportation solutions for business executives and corporate events.',
      features: [
        'Professional chauffeurs',
        'Corporate accounts',
        'Event transportation',
        'Roadshow service',
        'Conference transfers',
      ],
      image: corporateTransfer
    },
    {
      title: 'VIP Events',
      description: 'Luxury transportation for special occasions, weddings, and high-profile events.',
      features: [
        'Wedding service',
        'Red carpet events',
        'Customized packages',
        'Professional coordination',
        'Special occasions',
      ],
      image: vipEvents
    },
    {
      title: 'Luxury Tours',
      description: 'Exclusive sightseeing tours and luxury travel experiences across the UAE.',
      features: [
        'City tours',
        'Desert safaris',
        'Intercity transfers',
        'Custom itineraries',
        'Tourist attractions',
      ],
      image: luxuryTransport
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative h-[40vh] overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={servicesHero}
            alt="Our Services"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/50"></div>
        </div>
        <div className="relative container mx-auto px-6 lg:px-8 h-full flex items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4 tracking-tight">Our Services</h1>
            <p className="text-lg md:text-xl text-gray-300 leading-relaxed max-w-2xl">
              Experience unmatched luxury and comfort with our premium transportation services
            </p>
          </motion.div>
        </div>
      </div>

      {/* Services Grid */}
      <div className="container mx-auto px-6 lg:px-8 py-20">
        <div className="grid md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="bg-white rounded-lg overflow-hidden shadow-lg border border-gray-100"
            >
              <div className="h-64 overflow-hidden">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-500"
                />
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-bold text-[#1A1A1A] mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-3 mb-8">
                  {service.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-600">
                      <ChevronRightIcon className="h-5 w-5 text-[#C8A45D] mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <button
                  onClick={handleContactNavigation}
                  className="w-full bg-[#C8A45D] text-white py-3 px-6 rounded-lg font-semibold hover:bg-[#1A1A1A] transition-colors duration-300"
                >
                  Book Now
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
