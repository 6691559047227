import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import aboutUsImg from '../assets/images/about-us.webp';
import luxuryTransport from '../assets/images/luxury-transport.png';
import corporateTransfer from '../assets/images/corporate-transfer.png';

const About = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative h-[40vh] overflow-hidden">
        <div className="absolute inset-0">
          <img src={aboutUsImg} alt="About Us" className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/50"></div>
        </div>
        <div className="relative container mx-auto px-6 lg:px-8 h-full flex items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4 tracking-tight">About Us</h1>
            <p className="text-lg md:text-xl text-gray-300 leading-relaxed max-w-2xl">
              Your trusted partner in luxury transportation, delivering excellence since 2017
            </p>
          </motion.div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-6 lg:px-8 py-20">
        <div className="grid md:grid-cols-2 gap-12">
          {/* Our Story */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="flex flex-col justify-center"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-[#1A1A1A]">Our Story</h2>
            <div className="prose prose-lg max-w-none text-gray-600">
              <p className="mb-4">
                Since our establishment in 2010, Sharjah Transport Solutions has been setting the standard for luxury transportation in the UAE.
              </p>
              <p>
                Our commitment to excellence, attention to detail, and dedication to customer satisfaction have made us the preferred choice for discerning clients seeking premium transportation services.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="relative h-[400px] rounded-lg overflow-hidden shadow-xl"
          >
            <img src={luxuryTransport} alt="Our Story" className="w-full h-full object-cover" />
          </motion.div>

          {/* Our Mission */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="relative h-[400px] rounded-lg overflow-hidden shadow-xl md:order-3"
          >
            <img src={corporateTransfer} alt="Our Mission" className="w-full h-full object-cover" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="flex flex-col justify-center md:order-4"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-[#1A1A1A]">Our Mission</h2>
            <div className="prose prose-lg max-w-none text-gray-600">
              <p className="mb-4">
                Our mission is to provide unparalleled luxury transportation services that exceed our clients' expectations.
              </p>
              <p>
                We strive to combine luxury, safety, and reliability with exceptional customer service, ensuring every journey with us is memorable.
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About;
